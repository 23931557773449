<template>
  <section class="centerbox">
    <!--工具条-->
    <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>

    <!--列表-->
    <el-table
      :data="dtlist"
      v-loading="listLoading"
      @select="dialogCheck"
      @row-click="selectCurrentRow"
      ref="table"
      border
      style="width: 100%"
      class="custom-tbl"
    >
      <el-table-column type="selection" width="50"></el-table-column>
      <el-table-column type="index" width="80"></el-table-column>
      <el-table-column
        prop="Name"
        label="测算名称"
        width
        sortable
      ></el-table-column>  
      <el-table-column
        prop="CreateTime"
        label="创建时间"
        :formatter="formatCreateTime"
        width
        sortable
      ></el-table-column>  
      <el-table-column label="操作" width="150">
        <template slot-scope="scope">
          <el-button size="small" @click="drawershow(scope.$index, scope.row)">编辑参数</el-button>  
        </template>
      </el-table-column>
    </el-table>

    <!--工具条-->
    <el-col :span="24" class="toolbar">
      <el-pagination
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :page-size="50"
        :total="total"
        style="float: left"
      ></el-pagination>
    </el-col>

    <!--编辑界面-->
    <el-dialog
      title="编辑"
      :visible.sync="editFormVisible"
      v-model="editFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="editForm"
        label-width="80px"
        :rules="editFormRules"
        ref="editForm"
      >
        <el-form-item label="测算名称" prop="Name">
          <el-input
            v-model="editForm.Name"
            auto-complete="off"
          ></el-input>
        </el-form-item>   
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="editFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="editSubmit"
          :loading="editLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>

    <!--新增界面-->
    <el-dialog
      title="新增"
      :visible.sync="addFormVisible"
      v-model="addFormVisible"
      :close-on-click-modal="false"
    >
      <el-form
        :model="addForm"
        label-width="80px"
        :rules="addFormRules"
        ref="addForm"
      >
        <el-form-item label="测算名称" prop="Name">
          <el-input v-model="addForm.Name" auto-complete="off"></el-input>
        </el-form-item>  
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click.native="addFormVisible = false">取消</el-button>
        <el-button
          type="primary"
          @click.native="addSubmit"
          :loading="addLoading"
          >提交</el-button
        >
      </div>
    </el-dialog>
    <el-drawer
  :title="drawername"
  :visible.sync="drawer"
  :direction="direction" size="60%"  >
  <ComputeValue v-bind:Valueattribute="valueattribute"></ComputeValue>
</el-drawer>
  </section>
</template>

<script>
import util from "../../../util/date";
import {
  getComputeTypeListPage,
  removeComputeType,
  editComputeType,
  addComputeType, 
  getComputeTypeValueListPage,
  addSysOperateLog
} from "../../apis/apis";
import { getButtonList } from "../../promissionRouter";
import Toolbar from "../../components/Toolbar";
import ComputeValue from "./ComputeValue";
import routes from "../../router/index";

export default {
  components: { Toolbar,ComputeValue },
  data() {
    return {
      filters: {
        name: "",
      },
      buttonList: [],
      options: [],
      dtlist: [],
      defaultProps: {
        children: "children",
        label: "label",
      }, 
      total: 0,
      page: 1,
      listLoading: false,
      sels: [], //列表选中列
      currentRow: null,
      addDialogFormVisible: false,
      editFormVisible: false, //编辑界面是否显示
      editLoading: false,
      editFormRules: {
        Name: [{ required: true, message: "请输入类型名称", trigger: "blur" }], 
      },
      //编辑界面数据
      editForm: {
        Id: 0, 
        Name: "",   
      },

      addFormVisible: false, //新增界面是否显示
      addLoading: false,
      addFormRules: {
        Name: [{ required: true, message: "请输入类型名称", trigger: "blur" }], 
      },
      //新增界面数据
      addForm: {
        Name: "",   
      },
      isResouceShow: 0,
      drawer:false,
      direction: 'rtl',
      drawername:'',
      valueattribute:{tid:0,list:[],count:0}
    };
  },
  computed: {
    
  },
  methods: { 
    drawershow(index,row){ 
      var m = Object.assign({}, row);
      this.drawername=m.Name; 
      this.valueattribute.tid = m.Id;

     //操作日志
      let param = {  ExternalId: m.Id,Name:routes.history.current.name+"-编辑参数" };
      addSysOperateLog(param);

      let para = {
        page: 1, 
        computeTypeId:m.Id
      }; 

      getComputeTypeValueListPage(para).then((res) => {
        this.valueattribute.count = res.data.response.dataCount;
        this.valueattribute.list = res.data.response.data; 
      }); 

      this.drawer = true;
    },
    dialogCheck(selection, row) {
      this.currentRow = null;
      this.$refs.table.clearSelection();
      if (selection.length === 0) {
        return;
      }
      if (row) {
        this.selectCurrentRow(row);
      }
    },
    selectCurrentRow(val) {
      if (val) {
        this.currentRow = val;
        this.$refs.table.clearSelection();
        this.$refs.table.toggleRowSelection(val, true);
      }
    },
    callFunction(item) {
      this.filters = {
        name: item.search,
      };
      this[item.Func].apply(this, item);
    },
     
    formatCreateTime: function (row, column) {
      return !row.CreateTime || row.CreateTime == ""
        ? ""
        : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getComputeTypes();
    }, 
    getComputeTypes() { 
      let para = {
        page: this.page,
        key: this.filters.name,
      };
      this.listLoading = true;

      getComputeTypeListPage(para).then((res) => {
        this.total = res.data.response.dataCount;
        this.dtlist = res.data.response.data;

        this.listLoading = false;
        
      });
    },
    //删除
    handleDel() {
      let row = this.currentRow;
      if (!row) {
        this.$message({
          message: "请选择要删除的一行数据！",
          type: "error",
        });

        return;
      }
      this.$confirm("确认删除该记录吗?", "提示", {
        type: "warning",
      })
        .then(() => {
          this.listLoading = true;
          //NProgress.start();
          let para = { id: row.Id };
          removeComputeType(para).then((res) => {
            if (util.isEmt.format(res)) {
              this.listLoading = false;
              return;
            }
            this.listLoading = false;
            //NProgress.done();
            if (res.data.success) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
            } else {
              this.$message({
                message: res.data.msg,
                type: "error",
              });
            }

            this.getComputeTypes();
             //操作日志
             let param = {  ExternalId: row.Id,Name:routes.history.current.name+"-删除" };
            addSysOperateLog(param);
          });
        })
        .catch(() => {});
    },
    //显示编辑界面
    handleEdit() {
      let row = this.currentRow;
      this.options = [];
      if (!row) {
        this.$message({
          message: "请选择要编辑的一行数据！",
          type: "error",
        });

        return;
      }

   
      this.editFormVisible = true;
      this.editForm = Object.assign({}, row);
    },
    //显示新增界面
    handleAdd() {
      this.addFormVisible = true;
      this.options = [];
      this.addForm = { 
        Name: "", 
        IsDeleted:false,
      };
 
    },
    //编辑
    editSubmit: function () {
      this.$refs.editForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.editLoading = true;
            //NProgress.start();
            let para = Object.assign({}, this.editForm);

          

            if (this.$refs.treeEdit) {
              let pids = this.$refs.treeEdit.getCheckedKeys();
              para.Dids = pids.join(",");
            }

            editComputeType(para).then((res) => {
              if (util.isEmt.format(res)) {
                this.editLoading = false;
                return;
              }

              if (res.data.success) {
                this.editLoading = false; 
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["editForm"].resetFields();
                this.editFormVisible = false;
                this.getComputeTypes();
                 //操作日志
                 let param = {  ExternalId: para.Id,Name:routes.history.current.name+"-编辑" };
                addSysOperateLog(param);
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    //新增
    addSubmit: function () {
      let _this = this;
      this.$refs.addForm.validate((valid) => {
        if (valid) {
          this.$confirm("确认提交吗？", "提示", {}).then(() => {
            this.addLoading = true;
            //NProgress.start();
            let para = Object.assign({}, this.addForm);
  

            var user = JSON.parse(window.localStorage.user);

            if (user && user.uID > 0) {
              para.CreateId = user.uID; 
            } else {
              this.$message({
                message: "用户信息为空，先登录",
                type: "error",
              });
              _this.$router.replace(
                _this.$route.query.redirect ? _this.$route.query.redirect : "/"
              );
            }

           
            addComputeType(para).then((res) => {
              if (util.isEmt.format(res)) {
                this.addLoading = false;
                return;
              }
              if (res.data.success) {
                this.addLoading = false;
                //NProgress.done();
                this.$message({
                  message: res.data.msg,
                  type: "success",
                });
                this.$refs["addForm"].resetFields();
                this.addFormVisible = false;
                this.getComputeTypes();
                 //操作日志
               let param = {  ExternalId: res.data.response,Name:routes.history.current.name+"-新增" };
                addSysOperateLog(param);
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
            });
          });
        }
      });
    },
    getButtonList2(routers) {
      let _this = this;
      routers.forEach((element) => {
        let path = this.$route.path.toLowerCase();
        if (element.path && element.path.toLowerCase() == path) {
          _this.buttonList = element.children;
          return;
        } else if (element.children) {
          _this.getButtonList(element.children);
        }
      });
    },
  },
  mounted() {
    this.getComputeTypes();

    let routers = window.localStorage.router
      ? JSON.parse(window.localStorage.router)
      : [];
    //第一种写法，每个页面都需要写方法，但是可以做特性化处理
    // this.getButtonList(routers);

    //第二种写法，封装到 permissionRouter.js 中
    this.buttonList = getButtonList(this.$route.path, routers);
  },
};
</script>

<style scoped>
.custom-tbl /deep/ .has-gutter .el-checkbox {
  display: none;
}
.centerbox{
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  margin-bottom: 10rem;
}
</style>


<!-- <template>
  <div>
      <TypeValueTemp v-bind:dynamicValidateForm="dynamicValidateForm" ref="typevaluetemp"></TypeValueTemp>
      <el-button type="primary" @click="onSubmit">立即创建</el-button>
  </div> 
</template>

<script>
import {
  getAssetTypeValueListPage,
} from "../../apis/apis";
import TypeValueTemp from "@/components/TypeValueTemp";

export default { 
  data() {
      return { 
          dynamicValidateForm: {
              fromValueList: [], 
          },
      };
  },
  components: {
      TypeValueTemp,
},
  computed: {

  },
  methods: {
      getValueList() {
          let para = {
              assetTypeId: 1,
          };
          getAssetTypeValueListPage(para).then((res) => {
              console.log(res);
              if (res.data.success) {
                  this.dynamicValidateForm.fromValueList = res.data.response.data;
                  this.dynamicValidateForm.fromValueList.forEach(item => {
                      if(item.Type==6){ //多选
                          if(item.RecValue!=null){
                              item.RecValue = item.RecValue.split("|");
                          }else{
                              item.RecValue =[];
                          } 
                      }else if(item.Type==7){
                          if(item.RecValue!=null){
                              item.RecValue = new Date(item.RecValue)
                          }else{
                              item.RecValue =Date();
                          }
                      }
                     
                  }); 
              }

          });
      },
      onSubmit() {
         var subform = this.$refs.typevaluetemp.submitForm();
         subform.validate((valid) => {
          if (valid) {
              return true;
          } else { 
              return false;
          }
          }); 
      },
      
  },
  mounted() {
      this.getValueList()

  },
};
</script>
 
 -->